@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes transmitting /* Safari and Chrome */ {
  0% {
    -webkit-transform: translateX(0%) scale(0.02, 1);
    -o-transform: translateX(0%) scale(0.02, 1);
    transform: translateX(0%) scale(0.02, 1);
    opacity: 1;
  }
  35% {
    -webkit-transform: translateX(25%) scale(0.4, 0.5);
    -o-transform: translateX(25%) scale(0.4, 0.5);
    transform: translateX(25%) scale(0.4, 0.5);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(90%) scale(0.02, 1);
    -o-transform: translateX(90%) scale(0.02, 1);
    transform: translateX(90%) scale(0.02, 1);
    opacity: 0;
  }
}
@keyframes transmitting {
  0% {
    -ms-transform: translateX(0%) scale(0.02, 1);
    -moz-transform: translateX(0%) scale(0.02, 1);
    -webkit-transform: translateX(0%) scale(0.02, 1);
    -o-transform: translateX(0%) scale(0.02, 1);
    transform: translateX(0%) scale(0.02, 1);
    opacity: 1;
  }
  35% {
    -ms-transform: translateX(25%) scale(0.4, 0.5);
    -moz-transform: translateX(25%) scale(0.4, 0.5);
    -webkit-transform: translateX(25%) scale(0.4, 0.5);
    -o-transform: translateX(25%) scale(0.4, 0.5);
    transform: translateX(25%) scale(0.4, 0.5);
    opacity: 1;
  }
  100% {
    -ms-transform: translateX(90%) scale(0.02, 1);
    -moz-transform: translateX(90%) scale(0.02, 1);
    -webkit-transform: translateX(90%) scale(0.02, 1);
    -o-transform: translateX(90%) scale(0.02, 1);
    transform: translateX(90%) scale(0.02, 1);
    opacity: 0;
  }
}
.transmitting {
  -webkit-animation: transmitting 800ms linear 1s infinite;
  -moz-animation: transmitting 800ms linear 1s infinite;
  -ms-animation: transmitting 800ms linear 1s infinite;
  -o-animation: transmitting 800ms linear 1s infinite;
  animation: transmitting 800ms linear 1s infinite;
  transform-origin: 0 0;
  opacity: 0;
}
